<template>
  <section id="rules">
    <b-row class="match-height">
      <b-col cols="12">
        <two-good-table-column-search />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle, BOverlay,
} from 'bootstrap-vue'

import _ from 'lodash'
import TwoGoodTableColumnSearch from '@/views/table/vue-good-table/TwoGoodTableColumnSearch.vue'

export default {
  components: {
    TwoGoodTableColumnSearch,
    BRow,
    BCol,
  },
  data() {
    return {

    }
  },
  computed: {
  },
  mounted() {
  },
  created() {
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

table.vgt-table { font-size: 14px; }
table.vgt-table td { color: #363636; }

</style>
