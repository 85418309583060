<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="mb-1">
          Таблица избранных кампаний
        </b-card-title>
      </div>
      <!-- input search -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :to="{ path:`/dashboard/addFavComp/new` }"
              variant="outline-primary"
            >
              <feather-icon
                icon="FilePlusIcon"
                class="mr-50"
              />
              <span class="align-middle">Добавить избранные кампании</span>
            </b-button>
          </div>
        </b-form-group>
      </div>
    </b-card-header>
    <b-overlay
      variant="white"
      :show="showRuleLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-card-body>
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :is-loading="ruleTableLoading"
          style-class="vgt-table striped condensed"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <template slot="loadingContent">
            <b-overlay
              :show="ruleTableLoading"
              class="d-inline-block"
              rounded="sm"
            >
              Загружаем избранные кампании...
            </b-overlay>
          </template>
          <div
            v-show="!ruleTableLoading"
            slot="emptystate"
          >
            <div
              style="text-align: center;"
              class="center"
            >
              Нет данных
            </div>
          </div>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <div
              v-if="props.column.field === 'name'"
              class="text-nowrap"
            >
              <span class="text-nowrap">
                {{ props.row.name }}
              </span>
            </div>

            <div v-else-if="props.column.field === 'campaigns'">
              <b-link
                v-b-modal="'campaigns-' + props.row.id"
                style="text-decoration: dotted underline;
                                        text-underline-offset: 3px;"
              >
                Показать кампании (выбрано {{ counterCampaigns(props.row.campaigns) }})
              </b-link>

              <b-modal
                :id="'campaigns-' + props.row.id"
                ok-only
                ok-title="Понятно"
                centered
                title="Список кампаний"
              >
                <b-card-text>
                  <ul>
                    <li v-for="campaign in props.row.campaigns.split(',')" :key="campaign">
                      {{ campaign.trim() }}
                    </li>
                  </ul>
                </b-card-text>
              </b-modal>
            </div>
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-link
                  style="color: #363636"
                  @click="removeRule(props.row.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <b-tooltip
                    title="Удалить"
                    class="cursor-pointer"
                    :target="`invoice-row--send-icon` + props.row.id"
                  />
                </b-link>
              </span>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1" />
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BLink,
  BTooltip,
  BOverlay,
  BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton,
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import _ from 'lodash'
import { codeColumnSearch } from './code'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BCard,
    BOverlay,
    BButton,
    BTooltip,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      showRuleLoading: false,
      pageLength: 10,
      dir: false,
      codeColumnSearch,
      ruleTableLoading: false,
      columns: [
        {
          label: 'Название',
          field: 'name',
          sortable: false,
          width: '50',
          filterOptions: {
            enabled: true,
            placeholder: 'Название списка',
          },
        },
        {
          label: 'Дата создания',
          sortable: true,
          field: 'creationDate',
        },
        {
          label: 'Кампании',
          field: 'campaigns',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Кампания',
          },
        },
        {
          label: 'Действия',
          sortable: false,
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        0: 'light-success',
        1: 'light-primary',
        2: 'light-danger',
        3: 'light-danger',
        4: 'light-info',
        100: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    statusLabel() {
      const statusLabel = {
        /* eslint-disable key-spacing */
        0: 'Успешно запустилось',
        1: 'Отменено',
        2: 'Условия не выполнились',
        3: 'Ошибка запуска',
        4: 'Повтор',
        100: 'Создано',
        /* eslint-enable key-spacing */
      }

      return status => statusLabel[status]
    },
    activeProjectId() {
      return this.$store.state.project.activeProjectId
    },
  },
  watch: {
    activeProjectId(oldState, newState) {
      this.ruleTableLoading = true
      this.rows = []
      console.log(oldState, 'oldState')
      this.loadFavComps(oldState)
    },
  },
  mounted() {
    this.ruleTableLoading = true
    if (this.activeProjectId) {
      this.loadFavComps(this.activeProjectId)
    }
  },
  created() {
  },
  methods: {
    loadFavComps(projectId) {
      this.$http.get(`/favcomps?projectId=${projectId}`)
        .then(response => {
          const dataForParse = response.data
          const dataForTable = []
          Object.keys(dataForParse)
            .forEach(it => {
              const itemKey = dataForParse[it]
              const dateNow = new Date(Date.parse(itemKey.creationDate))
              const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
              const formattedDate = new Intl.DateTimeFormat('ru-RU', options).format(dateNow)
              const date = formattedDate

              const obj = {
                id: itemKey.id,
                name: itemKey.name,
                campaigns: '',
                creationDate: date,
                value: itemKey.value,
              }
              const camps = JSON.parse(itemKey.campaigns)
              const parseCamps = []
              Object.keys(camps)
                .forEach(c => {
                  parseCamps.push(camps[c])
                })
              obj.campaigns = parseCamps.join(', ')
              dataForTable.push(obj)
              this.rows = dataForTable/* .sort((a, b) => parseFloat(b.creationDate) - parseFloat(a.creationDate)) */
            })
          this.ruleTableLoading = false
        })
    },
    // eslint-disable-next-line consistent-return
    counterCampaigns(val) {
      if (val != null) {
        return val.split(',').length
      }
      return 0
    },
    removeRule(idRule) {
      this.$http.get(`/favcomps/remove?id=${idRule}`)
        .then(response => {
          this.rows.splice(_.indexOf(this.rows, _.find(this.rows, { id: idRule })), 1)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Избранные списки',
              icon: 'DeleteIcon',
              variant: 'success',
              text: 'Избранный список успешно удален',
            },
          }, {
            position: 'top-center',
          })
        })
    },
  },
}
</script>

<style>
.vgt-left-align {
  vertical-align: middle !important;
  padding-left: 25px !important;
}

.rule-pl {
  padding-left: 0 !important;
}
</style>
